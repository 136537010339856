var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticStyle: {
      "text-align": "center"
    }
  }, [_c("span", {
    staticClass: "text"
  }, [_vm._v("确认要将密码重置为“" + _vm._s(_vm.password) + "”吗?")]), _c("div", {
    staticClass: "constainer"
  }, [_c("a-button", {
    staticClass: "btn btn_l",
    staticStyle: {
      background: "#F5F5F5",
      border: "0"
    },
    attrs: {
      size: "large"
    },
    on: {
      click: _vm.cancle
    }
  }, [_vm._v(_vm._s("取消"))]), _c("a-button", {
    staticClass: "btn btn_r",
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      size: "large",
      type: "primary"
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v(_vm._s("确定"))])], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };
import "core-js/modules/es.number.constructor.js";
import { mapState, mapGetters, mapActions } from "vuex";
export default {
  name: "RsetStatus",
  components: {},
  props: {
    updateStatus: String | Number
  },
  data: function data() {
    return {};
  },
  computed: {},
  created: function created() {},
  methods: {
    cancle: function cancle() {
      this.$emit('handleAddCancle');
    },
    submit: function submit() {
      this.$emit('handleAddSubmit');
    }
  }
};
import { mapState, mapGetters, mapActions } from "vuex";
export default {
  name: "Pwd",
  components: {},
  props: {},
  data: function data() {
    return {
      password: this.genPassword(8)
    };
  },
  computed: {},
  created: function created() {},
  methods: {
    cancle: function cancle() {
      this.$emit('handleAddCancle');
    },
    submit: function submit() {
      this.$emit('handleAddSubmit', this.password);
    },
    genPassword: function genPassword() {
      var length = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 8;
      var chars = "0123456789abcdefghijklmnopqrstuvwxtz!@#$%^&*()_+?:{}[]ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      var password = "";
      for (var index = 0; index < length; index++) {
        var randomNum = Math.floor(Math.random() * chars.length);
        password += chars.substring(randomNum, randomNum + 1);
      }
      return password;
    }
  }
};